import '@mrhenry/wp--bugsnag-config';

/* Modules */
import './modules/input-sink';
import './modules/mr-accordion';
import './modules/mr-carousel.ts';
import './modules/mr-text-carousel';
import './modules/navigation-overlay';

import MrDeltaTime from '@mrhenry/wp--mr-delta-time';
customElements.define( 'mr-delta-time', MrDeltaTime );
