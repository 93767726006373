/**
 * 🚩 NO autoplay if UI to pause autoplay is missing.
 * @link https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=222#enough-time
 */

class MrTextCarousel extends HTMLElement {
	// MARK: properties.

	#currentIndex = 0;

	#itemWidth = 0;

	constructor() {
		super();
	}

	#clickHandler = ( e: MouseEvent ): void => {

		// Ignore clicks with modifier keys: shift, ctrl, alt,...
		if ( e.metaKey ) {
			return;
		}

		// Check if target exist and is instance of HTMLElement.
		if ( !e.target || !( e.target instanceof HTMLElement ) ) {
			return;
		}

		// Unknown trigger, not handling this event.
		if (
			!e.target.hasAttribute( 'data-carousel-next' ) &&
			!e.target.hasAttribute( 'data-carousel-previous' )
		) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		e.target.setAttribute( 'clicked', '' );

		setTimeout( () => {
			if ( e.target instanceof HTMLElement ) {
				if ( e.target.hasAttribute( 'clicked' ) ) {
					e.target.removeAttribute( 'clicked' );
				}
			}
		}, 512 );

		if ( e.target.hasAttribute( 'data-carousel-next' ) ) {
			this.goToNextItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-previous' ) ) {
			this.goToPreviousItem();

			return;
		}
	};

	// MARK: lifecycle.

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );

		this.#itemWidth = this.offsetWidth;

		requestAnimationFrame( () => {
			let resizeThrottle = false;

			window.addEventListener( 'resize', () => {
				if ( resizeThrottle ) {
					return;
				}

				resizeThrottle = true;

				requestAnimationFrame( () => {
					this.#itemWidth = this.offsetWidth;
					const container = this.querySelector( '[data-carousel-items]' ) as HTMLElement;
					container.style.transform = `translate3d(-${this.#itemWidth * this.#currentIndex}px, 0, 0)`;
					resizeThrottle = false;
				} );
			} );

		} );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );

		// Reset State.
		this.#currentIndex = 0;
	}

	// MARK: methods.

	private goToPreviousItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexMinusOneItem( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.transition();
	}

	private goToNextItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexPlusOneItem( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.transition();
	}

	private transition() {
		const container = this.querySelector( '[data-carousel-items]' ) as HTMLElement;
		const items = container.querySelectorAll( '[data-carousel-item]' );

		items?.forEach( ( item ) => {
			if ( !( item instanceof HTMLElement ) ) {
				return;
			}

			item.style.transform = 'translate3d(0, 0, 0)';
			item.style.opacity = '0';
		} );

		container.style.transform = `translate3d(-${this.#itemWidth * this.#currentIndex}px, 0, 0)`;

		setTimeout(
			() => {
				items?.forEach( ( item ) => {
					if ( !( item instanceof HTMLElement ) ) {
						return;
					}

					item.style.transform = 'translate3d(0, 0, 0)';
					item.style.transitionDelay = '128ms';
					item.style.transitionDuration = '640ms';
					item.style.transitionProperty = 'opacity';
				} );

				const currentItem = items[this.#currentIndex];
				if ( !currentItem || !( currentItem instanceof HTMLElement ) ) {
					return;
				}

				currentItem.style.opacity = '1';

			}
		);
	}
}

customElements.define( 'mr-text-carousel', MrTextCarousel );

function indexMinusOneItem( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index--;

	if ( 0 > index ) {
		if ( looping ) {
			return maxValue - 1;
		}

		return 0;

	}

	return index;
}

function indexPlusOneItem( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index++;

	if ( index >= maxValue ) {
		if ( looping ) {
			return 0;
		}

		return maxValue - 1;

	}

	return index;
}
